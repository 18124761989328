import React from "react";
import { useEffect, useState } from "react";
// import { Button, Col, Menu, Row } from "antd";
// import { Link, Route, Switch, useLocation } from "react-router-dom";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintSock,
  queryIpfsFunction,
  getTotalMint,
  addWhitelistAddress,
  setPresale,
  sendTrasaction,
  mintReserve
} from "../util/interact.js";

const Admin = (props) => {
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [qty, setQuantity] = useState(1);
  
    const [functionName, setFunctionName] = useState("");
    const [result, setResult] = useState("nothing yet");
    const [recipient, setRecipient] = useState("");
  
    // const location = useLocation();
  
    useEffect(async () => {
      const { address, status } = await getCurrentWalletConnected();
      
      setWallet(address);
      setStatus(status);
      setResult("page loaded");
  
      addWalletListener();
  }, []);
  

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  async function buttonPress(methodType) {
    if ('send' === methodType) {
      await sendTrasaction(functionName).then(res => {
        setResult(res.status);
      }).catch(res => {
        setResult(res.status);
      });
    } else {
      await getTotalMint().then(res => {
        setResult(res.status);
      });
    }
  };

  const setContractPresale = async() => {
    await setPresale().then(res => {
      setResult(res.status);
      setStatus('Presale Toggled');
    }).catch(res => {
      setResult(res.status);
      setStatus('Presale Toggled');
    });
  }

  const addWLAddress = async() => {
    var addresses = recipient.split(',');
    await addWhitelistAddress(addresses);
	  setStatus('wl added');
  }

  const onMintReserve = async () => {
    await queryIpfsFunction(qty, true).then(async function (res) {

      await mintReserve(recipient, res)
        .then(res => {
          setStatus(res.status);
      });
    })
  }

  return (
    <div className="Admin">
      <header>
      </header>

      <h3>Don't Get Too Excited...</h3>
      <p>Sure, gratz, you found my admin controls. Cool thing about blockchain, without my wallet, it's mostly useless.</p>

      <button id="walletButton" onClick={connectWalletPressed}>
            {walletAddress.length > 0 ? (
            "Connected: " +
            String(walletAddress).substring(0, 6) +
            "..." +
            String(walletAddress).substring(38)
            ) : (
            <span>Connect Wallet</span>
            )}
        </button>
        <div className="container">
            <div className="row align-items-center my-5">
                <div className="col-lg-7">
                    <img
                    className="img-fluid rounded mb-4 mb-lg-0"
                    src="http://placehold.it/900x400"
                    alt=""
                    />
                </div>
                <div className="col-lg-5">
                <h1 id="title">⛏️ Admin Tools ⛏️</h1>
                    <p>
                        /___\<br />
                        (∩｀-´)⊃━☆ﾟ.*･｡ﾟ
                    </p>

                    <br />
                    <button id="sendFunction" onClick={async () => {await buttonPress('send');} }>Send Function</button>
                    <button id="presale" onClick={setContractPresale}>Toggle Presale</button>
                    <button id="totalMint" onClick={getTotalMint}>Total Mint ?</button>

                    <p id="pendingStatus" style={{ color: "red" }}>
                        {status}
                    </p>
                    <label htmlFor="quantity">How Many? (Max 25):</label>
                    <input 
                      type="number" 
                      id="quantity" 
                      name="quantity" 
                      min="1" 
                      max="25"
                      value={qty}
                      onChange={(event) => setQuantity(event.target.value)} />
                    <button id="mintButton" onClick={onMintReserve}>
                      ** Reserve NFT **
                    </button>
                    <br />{result}
                    <h2>🖼 Add to whitelist: </h2>
                        <input
                        type="text"
                        placeholder="walletAddress"
                        onChange={(event) => setRecipient(event.target.value)}
                        /><br />
                    <button id="whitelist" onClick={addWLAddress}>Add to whitelist</button>
                    <br />{result}

                    <br></br>
                    <form>
                        <h2>🤔 Function Name: </h2>
                        <input
                        type="text"
                        placeholder="e.g. My first NFT!"
                        onChange={(event) => setFunctionName(event.target.value)}
                        />
                        <h2>✍️ I do nothing: </h2>
                        <input
                        type="text"
                        placeholder="e.g. Even cooler than cryptokitties ;)"
                        // onChange={(event) => setSend(event.target.value)}
                        />
                    </form>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Admin;