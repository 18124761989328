import Web3 from 'web3';
import env from "react-dotenv";
import { isatty } from 'tty';

const alchemyKey = env.MAINNET_URL;
const contractABI = require("../contract-abi.json");
const contractAddress = env.CONTRACT_ADDRESS;
const ownerAddress = env.PROD_OWNER_ADDRESS;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const http = require('http');
const price = .05;

var contract;
var web3;

window.addEventListener('load', function () {
  web3 = new Web3(Web3.currentProvider ? Web3.currentProvider : 
    alchemyKey ? createAlchemyWeb3(alchemyKey) : null);

  contract = new web3.eth.Contract(contractABI.abi, contractAddress);
}); 

export var isAdmin;


export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      isAdmin = (addressArray[0] == ownerAddress);
      const obj = {
        status: "👆🏽 Click a button.",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      isAdmin = (addressArray[0] == ownerAddress);

      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 Click a button.",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

function tryParseJSONObject (jsonString){
  try {
      var o = JSON.parse(jsonString);

      if (o && typeof o === "object") {
          return o;
      }
  }
  catch (e) { }

  return false;
};

export const mintReserve = async(toAddress, ipfs) => {
  return new Promise(function(resolve, rej) {
    var mintAddress = [];
    var j = 0;
    var i = 0;

    for (var k in ipfs){
      if (0 == j) {
        j = k;
      }
      mintAddress[i] = 'ipfs://' + ipfs[k];

      i++;
    } 

    var amount = mintAddress.length;

    contract.methods
      .mintReserve(toAddress, parseInt(j), mintAddress)
      .send({ 
        from: window.ethereum.selectedAddress,
        gas: 300000 * (100 - amount * 4) / 100 * amount
      })
      .on('transactionHash', (id) => { 
        if (id) {
            setProgState(1);
        } else {
            setProgState(2);
            console.log(id)
        }
        resolve({
          success: true,
          status: 'Success!',
        });
    })
    .catch(function(s) {
      setProgState(2);

      resolve(handleError(s));
    });
  });
}

export const mintSock = async(ipfs) => {
  return new Promise(function(resolve, rej) {
    var mintAddress = [];
    var j = 0;
    var i = 0;

    for (var k in ipfs){
      if (0 == j) {
        j = k;
      }
      mintAddress[i] = 'ipfs://' + ipfs[k];

      i++;
    } 

    var amount = mintAddress.length;

    contract.methods
      .mint(window.ethereum.selectedAddress, parseInt(j), mintAddress)
      .send({ 
        from: window.ethereum.selectedAddress,
        value: web3.utils.toWei(price * amount + "", "ether"),
        gas: 300000 * (100 - amount * 4) / 100 * amount
      })
      .on('transactionHash', (id) => { 
        if (id) {
            setProgState(1);
        } else {
            setProgState(2);
            console.log(id)
        }
        resolve({
          success: true,
          status: 'Success!',
        });
    })
    .catch(function(s) {
      setProgState(2);

      resolve(handleError(s));
    });
  });
}

function handleError(result) {
  if (result && typeof result === "object") {
    return {
      success: false,
      status: result.message,
    };
  } else {
    var err = result.message.substr(result.message.indexOf("'") + 1);
    err = JSON.parse(err.substr(0, err.length - 1));

    try {
      return {
        success: false,
        status: `${err['value']['data']['message']}❗|${err['value']['data']['data']['txHash']}`,
      };
    } catch {
      return {
        success: false,
        status: 'Unknown Error',
      };
    }
  }
}

export const addWhitelistAddress = async(addresses) => {
  return new Promise(function(resolve, rej) {
    contract.methods.setPresaleAddress(addresses).send({
      from: window.ethereum.selectedAddress,
      gas: 800000
    })
    .then(function(resp) {
      if (resp) {
          setProgState(1);
      } else {
          setProgState(2);
          console.log(resp)
      }
      resolve({
        success: true,
        status: 'Success!',
      });
    })
    .catch(function(s) {
      setProgState(2);
      rej(handleError(s));
    });
  });
};

export const sendTrasaction = async(methodName) => {
  return new Promise(async function(resolve, rej) {
    contract.methods[methodName]().send({ 
      from: window.ethereum.selectedAddress,
      gas: 800000
    })
    .then(function(resp) {
      if (resp) {
          setProgState(1);
      } else {
          setProgState(2);
          console.log(resp)
      }
      resolve({
        success: true,
        status: 'Success!',
      });
    })
    .catch(function(s) {
      setProgState(2);
      rej(handleError(s));
    });
  });
}

export const getTotalMint = async() => {
  return new Promise(function(resolve, rej) {
    contract.methods.totalMint().call()
      .then(function(resp) {
          if (null != resp) {
            setProgState(1);
            resolve(resp);
        } else {
            setProgState(2);
            rej(handleError(resp));
        }
      })
      .catch(function(s) {
        setProgState(2);
        rej(handleError(s));
      });
  });
}

export const setPresale = async() => {
  return new Promise(async function(resolve, rej) {
    contract.methods.togglePresale().send({ 
      from: window.ethereum.selectedAddress,
      gas: 800000
    })
    .then(function(resp) {
      if (resp) {
          setProgState(1);
          resolve(resp);
      } else {
          setProgState(2);
          rej(handleError(resp));
      }
    })
    .catch(function(s) {
      setProgState(2);
      rej(handleError(s))
    });
  });
}

export function queryIpfsFunction(qty, reserve = false) {
  return new Promise(function(resolve, rej) {
    fetch('https://us-central1-stonksock-a5565.cloudfunctions.net/getNextTokenIpfs', {
        method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Content-Length': qty.length,
      },
      body: JSON.stringify( {'qty': qty, 'reserve': reserve})
      }).then(async function(response) {
        await response.json().then(body => {
          console.log(`statusCode: ${body.status}`);
        
          resolve(body.data);

          return "address not found";
        });
      })
    });
};

function GetById(id) {
  return document.getElementById(id);
};


  /*
  0 -pending
  1 -completed
  2 -error
  -1 -idle
  */
  function setProgState(state, idIndicator="pendingStatus"){
    if(state === 0){
        GetById(idIndicator).style.display = "block";
        GetById(idIndicator).innerHTML = "⏳ pending...";
    }else if(state===1){
        GetById(idIndicator).style.display = "block";
        GetById(idIndicator).innerHTML = "✅ completed ✅";
    }else if(state===2){
        GetById(idIndicator).style.display = "block";
        GetById(idIndicator).innerHTML = "⚠ error :(";
    }else if(state===-1){
        GetById(idIndicator).style.display = "none";
    }
}

