import React from "react";

function Contact() {
  return (
    <div className="contact">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src="http://placehold.it/900x400"
              alt=""
            />
          </div>
          <div className="col-lg-5">
            <h1 className="font-weight-light">Contact</h1>
            <ul>
                <li>Verified Contract:<br />
                  <a href="https://etherscan.io/address/0xf24f3b174fbad3e60c8f5d34170c74985ce3ee54"><img src="../assets/contract.jpg" width="70px" /></a>
                </li>
                <li>OpenSea Collection:<br />
                  <a href="https://opensea.io/collection/stonksock"><img src="../assets/openSea.png" width="70px" /></a>
                </li>
                <li>Discord:<br />
                  <a href="https://discord.gg/BJZF52br"><img src="../assets/discord.jpg" width="50px" /></a>
                </li>
                <li>Twitter: <br />
                  <a href="https://twitter.com/StonkSock_NFT"><img src="../assets/twitter.png" width="50px" /></a>
                </li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;