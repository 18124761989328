import React from "react";

function WhitePaper() {
  return (
    <div className="whitepaper">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-10">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src="http://placehold.it/900x400"
              alt=""
            />
          </div>
          <div className="col-lg-10">
            <h1 className="font-weight-light">SockPaper</h1>
            <p>
              If I had a whitepaper, this would be it.<br /><br />I want to make a couple things clear:
              <ul>
                <li>So far, I am a party-of-one. I do not have a team to support me or this project</li>
                <li>I'm not an artist, Not a UI designer, but I am a programmer and an avid crypto/stock enthusiast.</li>
                <li>This is a legit project, though it is held together with the virtual equivilent of duct-tape, at some points.</li>
                <li>I tried to define a secure method of reveal on distribution. This will allow for a long-running 'mint', since...</li>
                <li>I consider myself lucky if even one person reads this.</li>
                <li>As of today, there is no utility to go with this collection, purely bragging rights. (mostly mine)</li>
                <li>If this project happens to grow a community, I will gladly support it and put more into it. But, it has taken me 4+ months of ignoring my responsibilities to develop this, and I need to do some laundry, and probably take out the trash, twice.</li>
                <li>If the community so desires, I can find a way to turn this into the most dumb, cool nft game ever. Like <i>Legendary</i>. (Now taking suggestions for coolest game ever, involving a sock)</li>
                <li>Dox me. I'm just one preson. If you want to know more, I'm sure there's a link to a discord on here somewhere.</li>
                <li>I'm kind of curious if someone can determine how to identify rare items in this collection, since it's all revealed at the start.</li>
                <li><strong>Free NFT</strong> to the one who does. (Contact me with Details on extracting the FULL collection)</li>
                <li>Max Mint per Presale -&gt; 5</li>
                <li>Max Mint per Public Sale -&gt; 20 / wallet</li>
                <li><del>I don't think anyone is waiting on this to happen, but, just in case, This launch is going to be delayed. I had no idea just how much it cost to deploy a contract to ETH. I'm going to have to delay this just a bit longer, but if you want to be whitelisted, find me in Discord to provide your address</del></li>
                <li>Presale Is Live</li>
                <li></li>
                <li>I doth hereby declare on this day that this list is subject to be modified until I hath deemed it finished. I probably want to modify it as time goes on.</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhitePaper;